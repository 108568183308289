<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
  >
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        dark
        :outlined="maxNum > 0"
        :color="countColor"
        class="mb-3"
        v-bind="attrs"
        v-on="on"
      >
        <span v-if="maxNum > 0">
          Ticket N. {{ currentNum }}/<b>{{ maxNum }}</b>
        </span>
        <span v-else>
          Configure requests limit
        </span>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">
          Change maximum requests number
        </span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <p class="my-3 ms-8 text-body-1">
            <b>Current requests count:</b> {{ currentNum }}<br>
            <span class="text-caption">Set <b>0</b> to remove the limit.</span>
          </p>
          <v-form
            ref="appUserForm"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              v-model="newLimit"
              type="number"
              label="Insert max limit"
              append-outer-icon="mdi-plus-thick"
              prepend-icon="mdi-minus-thick"
              :rules="limit_rule"
              @click:append-outer="newLimit += 1"
              @click:prepend="newLimit -= 1"
            />
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="close()"
        >
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :disabled="!valid"
          @click="save()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { getRegExp } from '@/formRules.js';

export default {
  props: {
    crmId: {
      type: String,
      default: ''
    },
    currentNum: {
      type: Number,
      default: 0
    },
    maxNum: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      overlay: false,
      dialog: false,
      valid: true,
      newLimit: this.maxNum,
      limit_rule: [
        ...getRegExp('positive_numbers')
      ]
    };
  },
  computed: {
    countColor() {
      const diff = this.maxNum - this.currentNum;

      if (this.maxNum > 0 && diff <= 5) {
        return diff <= 0 ? 'red' : 'orange';
      }

      return 'green';
    }
  },
  watch: {
    $route() {},
    maxNum: {
      handler(newValue) {
        if (newValue > 0) {
          this.newLimit = this.maxNum;
        }
      }
    }
  },
  methods: {
    ...mapActions(['editTicketingRequestLimitAction']),
    async save() {
      this.overlay = true;
      const params = {
        crm_id: this.crmId,
        request_limit: this.newLimit
      };
      await this.editTicketingRequestLimitAction(params);
      this.overlay = false;
      this.dialog = false;
      this.$emit('check-ticketing-request-limit');
    },
    close() {
      this.newLimit = this.maxNum;
      this.dialog = false;
    }
  }
};
</script>
